import React from 'react'
import styles from './AboutUs.module.css';
import certificate from './../../assets/title_certificate.png'
import samir from './../../assets/samir.png'
import childChess from './../../assets/child_chess.jpg'

const AboutUs = () => {
    return (
        <div className={styles.therapyPlansContainer}>
        <h2 id='about' className={styles.uniqueTitle}>ABOUT US</h2>
        <div className={styles.therapyPlansSection}>
            <div className={styles.textContent}>
                <h2>Why Choose Us</h2>
                <p>
                SD Wonderful World Of Chess stands out for its comprehensive approach to youth chess education, combining structured training with an emphasis on cognitive development and competitive success. Our certified and experienced coach is passionate about unlocking each student's potential, ensuring they not only excel at chess but also develop valuable life skills.
                </p>
            </div>
            <div className={styles.imageContent}>
                <img
                    src={childChess}
                    alt="Child playing chess"
                    className={styles.childImage}
                />
            </div>
        </div>
        <div className={styles.guidanceSupportContainer}>
            <div className={styles.imageContent}>
                <img
                    src={samir}
                    alt="Profile"
                    className={styles.childImage}
                />
            </div>
            <div className={styles.textContainer}>
                <h2>Meet Samir</h2>
                <p>
                Meet Samir Dervisevic, an Arena FIDE Master with a rich portfolio of victories in numerous tournaments and professional chess matches. Passionate about the game and dedicated to the art of strategy, Samir is committed to sharing his extensive chess knowledge with the next generation. He strives to inspire and educate young enthusiasts, helping them to not only master the rules of the game but also to embrace the critical thinking and dedication required to excel both on and off the chessboard.
                </p>
            </div>
        </div>

            <div className={styles.certificate}>
                <img
                    src={certificate}
                    alt="chess certificate"
                    className={styles.childImage}
                />
            </div>
    </div>
    );
  };

export default AboutUs;