import React from 'react';
import { Header, Services, AboutUs, Contact } from './container';
import Navbar from './components/Navbar/Navbar';
import './App.css';


const App = () => (
  <div>
    <Navbar />
    <Header />
    <Services/>
    <AboutUs/>
    <Contact/>
  </div>
);

export default App;
