import React from "react";
import chessHand from "./../../assets/chess-playing-hand.webp";
import styles from "./Header.module.css";

const scrollToServices = () => {
  const servicesElement = document.getElementById('contact');
  if (servicesElement) {
    servicesElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

const Header = () => {
  return (
    <div>
      <div className={styles.hero}>
        <img src={chessHand} alt="chess hand hero"></img>
        <div className={styles.heroContent}>
          <h1>CHESS FOR THE YOUTH</h1>
          <p>
            Welcome to a world where young minds master strategy,
            develop critical thinking, and unlock their
            potential—one move at a time.
          </p>
          <button onClick={scrollToServices} className={styles.button}>Enroll Now</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
