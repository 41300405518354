import React from 'react'
import styles from './Contact.module.css';
import { FaPhone } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";


const Contact = () => {
    return (
        <div className={styles.contact}>
        <h2 id='contact' className={styles.uniqueTitle}>CONTACT US</h2>
          <p>Contact us to Get Started!</p>
        <div className={styles.grid}>
        <div className={styles.gridItem}>
          <div className={styles.icon}> <FaPhone /></div> 
          <h3>Call</h3>
          <a href="tel:555-555-5555">+1 347-972-5853</a>
        </div>
        <div className={styles.gridItem}>
        <div className={styles.icon}> <MdOutlineEmail /></div> 
          <h3>Email</h3>
          <a href='mailto:samirdervisevic18@gmail.com'>samirdervisevic18@gmail.com</a>
        </div>
      </div>
        </div>
    )
}

export default Contact;