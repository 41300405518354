import React from 'react';
import styles from './Services.module.css';
import { FaChessPawn, FaChalkboardTeacher, FaChessBoard, FaChessKnight } from "react-icons/fa";
import { IconContext } from "react-icons";
import { IoPeopleOutline } from "react-icons/io5";

const scrollToServices = () => {
  const servicesElement = document.getElementById('contact');
  if (servicesElement) {
    servicesElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

const Services = () => {
  return (
    <><div className={styles.services}>
      <h1 id="services" className={styles.title}>OUR SERVICES</h1>
      <div className={styles.serviceGrid}>
        {/* Each service block */}
        <div className={styles.mainService}>
          <h2>Chess Education</h2>
          <p>Teachers and parents around the world know that chess is not a simple game. It is a great learning tool that will help build your child’s executive function skills.</p>
          <IconContext.Provider value={{ color: "#eec11f", size: "1em", className: "global-class-name" }}>
            <p><FaChessPawn /> Enhances cognative skills such as critical thinking and problem-solving</p>
            <p><FaChessPawn /> Improves memory and concentration abilities</p>
            <p><FaChessPawn /> Develops decision making skills and strategic thinking</p>
            <p><FaChessPawn /> Teaches pacience, perseverance and resilience</p>
          </IconContext.Provider>
        </div>

        {/* Repeat for other services */}
        <div className={styles.otherServices}>
          <div className={styles.serviceItem}>
            <div className={styles.icon}><IoPeopleOutline /></div>
            <h2>Various Age Groups</h2>
            <p>We provide chess classes to children and young adults, from ages 4 to 21.</p>
          </div>

          <div className={styles.serviceItem}>
            <div className={styles.icon}><FaChessKnight /></div>
            <h2>Beginner Fundamentals</h2>
            <p>Introduce the basic rules, movements, and objectives of chess.</p>
          </div>

          <div className={styles.serviceItem}>
            <div className={styles.icon}><FaChalkboardTeacher /></div>
            <h2>Private Coaching</h2>
            <p>We offer personalized instruction tailored to the specific needs and goals of the student.</p>
          </div>

          <div className={styles.serviceItem}>
            <div className={styles.icon}><FaChessBoard /></div>
            <h2>Best Chess Openings</h2>
            <p>Our students know all the best chess openings and how to use them.</p>
          </div>
        </div>
      </div>
      <div className={styles.pricing}>
        <h2>Pricing</h2>
        <p>Individual 1 hour class: $70</p>
        <p>Group 1 hour class: $40</p>
      </div>
    </div><div className={styles.contactUs}>
        <h1>Contact Us To Get Started</h1>
        <button className={styles.button} onClick={scrollToServices}>Enroll Now</button>
      </div></>
  );
};

export default Services;
