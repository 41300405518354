import React, {useState} from 'react'
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from "react-icons/io";
import { FaChess } from "react-icons/fa";
import { IconContext } from "react-icons";
import styles from './Navbar.module.css'


const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return(
    <nav className={styles.navbar}>
      <IconContext.Provider value={{ color: "white", size: "3em", className: "global-class-name" }}>
        <div className={styles.navbar_logo}>
          <FaChess />
        </div>
      </IconContext.Provider>;

      <ul className={styles.navbar_links}>
        <li className='p__opensans'><a href="#services">SERVICES</a></li>
        <li className='p__opensans'><a href="#about">ABOUT US</a></li>
        <li className='p__opensans'><a href="#contact">CONTACT US</a></li>
      </ul>
      
      <div className={styles.navbar_smallscreen}>
        <GiHamburgerMenu color='#fff' fontSize={27} onClick={()=> setToggleMenu(true)}/>

        {toggleMenu && (
          <div className={styles.navbar_smallscreen_overlay} flex__center slide_bottom>
            <IoMdClose color='white' fontSize={27} className='overlay__close' onClick={()=> setToggleMenu(false)}/>
        
            <ul className={styles.navbar_smallscreen_links}>
              <li className='p__opensans'><a onClick={()=> setToggleMenu(false)} href="#services">SERVICES</a></li>
              <li className='p__opensans'><a onClick={()=> setToggleMenu(false)} href="#about">ABOUT US</a></li>
              <li className='p__opensans'><a onClick={()=> setToggleMenu(false)} href="#contact">CONTACT US</a></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  )
};

export default Navbar;